<template>
  <div class="modal-bg">
    <div class="tournament-registration-modal">
      <svg class="close-modal" @click="closeModal" width="24" height="24" viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M3 3L21 21M21 3L3 21" stroke="white"/>
      </svg>
      <!-- div -> router-link :to="{name: 'team.name', params: {id: currentTeam.id}}" target="_blank" -->
      <div class="team">
        <div class="logo">
          <img :src="`${currentTeam.logo}`" alt="#">
        </div>
        <div class="text">
          <div class="name">{{ currentTeam.attributes.name }}</div>
          <div class="sponsor">{{ currentTeam.attributes.sponsor }}</div>
        </div>
      </div>
      <div class="head">
        Заявка на участие
      </div>
      <div class="placeholder">Подтвердите состав для участия в турнире</div>
      <div class="body">
        <template v-if="!usersInProgress">
          <div class="major players__wrap">
            <div class="title">Основной</div>
            <div class="players">
              <tournament-registration-member-card
                  v-for="(player, index) in majorPlayers"
                  :key="index"
                  :player="player"
              />
            </div>
          </div>
          <div class="reserve players__wrap">
            <div class="title">Запас</div>
            <div class="players">
              <tournament-registration-member-card
                  v-for="(player, index) in reservePlayers"
                  :key="index"
                  :player="player"
              />
            </div>

            <div v-if="!isMajorsFull" class="decline-text">{{ declineText }}</div>
            <div class="actions">
              <!-- div class="empty_btn" -> router-link :to="{name: 'team.name', params: {id: currentTeam.id}}" target="_blank" -->
              <div class="empty_btn">Изменить состав</div>
              <div class="filled__yellow" @click="createTournamentEntrie" :class="{disabled: !isMajorsFull}">Подать заявку</div>
            </div>
          </div>
        </template>
        <div v-else class="spinner__wrap">
          <spinner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TournamentRegistrationMemberCard from "@/components/modals/tournament-registration/tournament-registration-member-card";
import {mapGetters, mapActions} from 'vuex';
import Spinner from "@/components/UIElements/spinner";
import {makeElFirstInArrByRole} from "@/otherJS/makeElFirstInArrByRole";

export default {
  name: "tournament-registration",
  components: {Spinner, TournamentRegistrationMemberCard},
  props: {
    tournament: {},
    composition: {},
  },
  data() {
    return {
      activeTeam: 0,
      usersInProgress: true,
      compositionUsers: null,
      isMajorsFull: true,
      majorPlayers: null,
      reservePlayers: null,
      declineText: ''
    }
  },
  computed: {
    ...mapGetters([
      'USER',
      'USER_MANAGED_TEAMS',
      'USER_CAPTAIN_TEAMS',
      'USERS_FOR_TOURNAMENT_REGISTRATION',
      'GAMES'
    ]),
    currentTeam() {
      return this.USER_MANAGED_TEAMS.concat(this.USER_CAPTAIN_TEAMS)[0]
    },
    majorCompositionPlayers() {
      let players = [];
      let majors = this.USERS_FOR_TOURNAMENT_REGISTRATION.filter(player => player.role !== 'substitute');
      if (majors.length) {
        players = majors;
      }
      return players
    },
    reserveCompositionPlayers() {
      let players = []
      let subs = this.USERS_FOR_TOURNAMENT_REGISTRATION.filter(player => player.role === 'substitute');
      if (subs.length) {
        players = subs;
      }
      return players;
    },
    relatedGame() {
      return this.GAMES.find(el => Number(el.id) === Number(this.composition.attributes.game_id))
    }
  },
  methods: {
    ...mapActions([
      'GET_USER_MT_USERS_LIST',
      'CREATE_TOURNAMENT_ENTRIE',
      'GET_COMPOSITION_USERS'
    ]),
    closeModal() {
      this.$emit('closeModal')
    },
    sortPlayersToArr(playersToPush, total, type) {
      let players = playersToPush;
      let diff = total - players.length;

      for (let i = 0; i < diff; i++) {
        players.push({empty: true})
      }

      if (type === 'majors') {
        makeElFirstInArrByRole(players, 'capitan');
        this.isMajorsFull = diff === 0
        this.declineText = this.isMajorsFull ? '' : 'В текущем составе не хватает игроков. Пожалуйста заполните основной состав.'
      }

      return players
    },
    async setPlayers() {
      await this.GET_COMPOSITION_USERS({composition_id: this.composition.id})

      this.majorPlayers = this.sortPlayersToArr(this.majorCompositionPlayers, this.relatedGame.attributes.number_of_players, 'majors')
      this.reservePlayers = this.sortPlayersToArr(this.reserveCompositionPlayers, this.relatedGame.attributes.number_of_substitute, 'reserve')

      this.usersInProgress = false
    },
    createTournamentEntrie() {
      let allUsers = this.majorPlayers.concat(this.reservePlayers);
      let usersMeta = allUsers.map(user => {
        return {
          id: user.id,
          role: user.role
        }
      })
      let usersData = allUsers.map(user => {
        return {
          id: user.id,
          type: 'users'
        }
      })

      let data = {
        'participantable_type': 'teams',
        'participantable_id': this.currentTeam.id,
        'tournament_id': this.tournament.id,
        'usersMeta': usersMeta,
        'usersData': usersData
      }

      this.CREATE_TOURNAMENT_ENTRIE(data)
      this.closeModal();
      this.$emit('showThanks')
    }
  },

  watch: {
    GAMES() {
      if (this.GAMES.length !== 0) this.setPlayers();
    }
  },
}
</script>

<style scoped lang="scss">
.spinner__wrap {
  height: 100%;
}

.empty_btn {
  margin-bottom: 4px;
}
</style>